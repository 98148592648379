import { Box, Container, Stack, Typography } from '@mui/material';
import React from 'react';
import appConfig from 'src/appConfig';
import { IMAGES } from 'src/appConfig/images';
import { isEmpty } from 'src/validations';

const Footer: React.FC<Props> = () => {
  return (
    <Box>
      <Box
        bgcolor={'grey.700'}
        color="white"
        sx={{
          backgroundImage: `url(${IMAGES.backgroundDiamondFooter})`,
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'bottom left',
          backgroundSize: 'contain',
        }}
      >
        <Container
          maxWidth="md"
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box>
            <Stack flexDirection={'row'} justifyContent="center" alignItems="center" py={1} gap={1}>
              <Typography
                variant="body2"
                fontWeight={'bold'}
                color="white"
                style={{
                  marginBottom: '1px',
                }}
              >
                GENERAL INQUIRIES
              </Typography>
              <Typography variant="subtitle1" color="white">
                rcuh@rcuh.com
              </Typography>
            </Stack>
            <Stack flexDirection={'row'} justifyContent="center" alignItems="center" gap={1}>
              <Typography
                variant="body2"
                fontWeight={'bold'}
                color="white"
                style={{
                  marginBottom: '1px',
                }}
              >
                MAILING ADDRESS
              </Typography>
              <Typography variant="subtitle1" color="white">
                1601 East-West Road, Burns Hall 4th Floor, Makai Wing, Honolulu, HI 96848
              </Typography>
            </Stack>
          </Box>
          <Box sx={{ display: 'flex', pt: 3, width: '600px' }}>
            <Box
              sx={{
                width: '33%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <TypoTitle text={'Finance'} />
              <TypoTitle text={`(808) 956-0500`} />
              <TypoTitle text={`rcuhfinance@rcuh.com`} />
            </Box>

            <Box
              sx={{ width: '33%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
            >
              <TypoTitle text={'Human Resources'} />
              <TypoTitle text={`(808) 956-3100`} />
              <TypoTitle text={`rcuhhr@rcuh.com`} />
            </Box>

            <Box
              sx={{ width: '33%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}
            >
              <TypoTitle text={'Executive Office'} />
              <TypoTitle text={`(808) 956-0503`} />
              <TypoTitle text={`rcuhed@rcuh.com`} />
            </Box>
          </Box>
          <Box>
            <Stack flexDirection={'row'} justifyContent="center" alignItems="center" py={1} gap={1}>
              <Typography variant="body2" fontWeight={'bold'} color="white">
                If you require assistance in accessing content on this website, please contact
              </Typography>
              <Typography variant="body2" color="white">
                rcuh@rcuh.com
              </Typography>
            </Stack>
          </Box>
        </Container>
      </Box>
      <Box bgcolor={'primary.main'} py={1} borderTop={'1px solid white'}>
        <Stack flexDirection={'row'} justifyContent="center" alignItems="center" my={1}>
          <Typography variant="body2" mr={3} color={'white'}>
            © {new Date().getFullYear()} Research Corporation of the University of Hawai’i
          </Typography>
          <Typography variant="body2" color={'white'}>
            v{appConfig.APP_VERSION}
          </Typography>
        </Stack>
      </Box>
    </Box>
  );
};

const TypoTitle = ({ text }) => {
  return (
    <Typography variant="subtitle1" fontWeight={'bold'} color={'white'}>
      {!isEmpty(text) ? text : <br />}
    </Typography>
  );
};

type Props = {};

export default Footer;
