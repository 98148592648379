import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from '../apiClient';
import { authResponseWrapper } from '../helpers';
import { LogPayload } from './types';

export function useLog(options?: UseMutationOptions<any, Error, LogPayload>) {
  const { mutate, isLoading } = useMutation<any, Error, LogPayload>({
    mutationFn: (payload: LogPayload) => authResponseWrapper(apiClient.postLog, [payload]),
    ...options,
  });

  return {
    log: mutate,
    isLoading,
  };
}
