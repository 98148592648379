import { useMutation, UseMutationOptions } from 'react-query';
import apiClient from '../apiClient';
import { authResponseWrapper } from '../helpers';
import { ForgotPasswordPayload } from './types';

export function useMigrateUser(options?: UseMutationOptions<any, Error, ForgotPasswordPayload>) {
  const { mutate: migrateUser, isLoading } = useMutation<any, Error, ForgotPasswordPayload>({
    mutationFn: (payload: ForgotPasswordPayload) =>
      authResponseWrapper(apiClient.migrateUser, [payload]),
    ...options,
  });

  return {
    migrateUser,
    isLoading,
  };
}
